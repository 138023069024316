<template>
  <transition name="fade">
    <header
      v-show="vpmoState.vpmoInit != false"
      class="header"
      :class="['deskNav', { navDownBg: isScrolled }]"
    >
      <div class="container md:pt-5">
        <nav v-if="navigation" class="nav-container grid w-full grid-cols-12">
          <div class="nav-left hidden lg:block col-span-5">
            <ul class="fyCenter">
              <li v-for="item in leftNav" :key="item.id">
                <btn-comp
                  variant="nav"
                  btntype="a"
                  btnclass="mr-12"
                  :href="item.link"
                  :name="item.title"
                  @click.prevent="handleNavigation(item.link)"
                />
              </li>
            </ul>
          </div>
          <div
            class="nav-center flex items-center justify-between lg:justify-center col-span-12 lg:col-span-2"
          >
            <div class="mobileNav block lg:hidden">
              <div class="fiButtonWrapp" @click="toggleMenu">
                <div
                  :class="['fiButton', { active: isActive }]"
                  data="hamburger-menu"
                >
                  <b></b>
                  <b></b>
                  <b></b>
                </div>
              </div>
            </div>
            <router-link to="/" class="mobileLogoButton">
              <CompLogos class="deskNavLogo" comp="fitzek_prime" />
            </router-link>
            <div class="block lg:hidden"></div>
          </div>
          <div
            class="nav-right hidden lg:block col-span-5"
            :class="{ open: isActive }"
          >
            <ul class="fyCenter fxEnd">
              <li v-for="item in rightNav" :key="item.id">
                <btn-comp
                  variant="nav"
                  btntype="a"
                  btnclass="ml-4"
                  :href="item.link"
                  :name="item.title"
                  @click.prevent="handleNavigation(item.link)"
                />
              </li>
              <li>
                <btn-comp
                  variant="outline"
                  btntype="a"
                  btnclass="ml-12"
                  href="#bestellen"
                  :name="navigation.acf.ordertitle"
                  @click.prevent="handleNavigation('#bestellen')"
                />
              </li>
            </ul>
          </div>
        </nav>

        <!-- Sound UI ------------------ -->
        <div
          id="background_sound"
          class="background_sound__wrapper"
          :class="{ hint: !hasClicked }"
        >
          <button @click="playAudio" aria-label="Sound an/aus">
            <img
              v-show="state.isMuted"
              class="soundBtn unmuted"
              src="https://kla.brotsalz.de/wp-content/uploads/2024/08/sound-unmuted.svg"
              alt="Unmuted"
            />
            <img
              v-show="!state.isMuted"
              class="soundBtn muted"
              src="https://kla.brotsalz.de/wp-content/uploads/2024/08/sound-muted.svg"
              alt="Muted"
            />
          </button>
          <audio
            ref="audioPlayer"
            src="https://kla.brotsalz.de/wp-content/uploads/2024/08/atmo.aac"
            loop
          ></audio>
        </div>

        <!-- Fullscreen Overlay ------------------ -->
        <div
          :style="{
            backgroundImage: `url(${navigation.acf.overlaybg.url})`,
          }"
          v-if="navigation"
          v-show="isActive"
          class="header_overlay"
        >
          <div class="overlayCont">
            <ul class="header_overlay-nav">
              <li class="mb-4" v-for="item in combinedNav" :key="item.id">
                <a
                  class="f-heading text-5xl"
                  :href="item.link"
                  @click.prevent="
                    handleNavigation(item.link), (isActive = false)
                  "
                  >{{ item.title }}</a
                >
              </li>
              <li class="mb-4 mt-6">
                <btn-comp
                  variant="outline"
                  btntype="a"
                  btnclass="mx-auto"
                  href="#bestellen"
                  :name="navigation.acf.ordertitle"
                  @click.prevent="
                    handleNavigation('#bestellen'), (isActive = false)
                  "
                />
              </li>
            </ul>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-16">
              <div class="mb-12">
                <div class="text-center">
                  <div class="socialCompLogoCont">
                    <CompLogos comp="fitzek" />
                  </div>
                  <div class="subHeadline uppercase followHeadline">
                    Folge Sebastian Fitzek hier
                  </div>
                  <div class="fCenter">
                    <SocialIcons comp="fitzek" />
                  </div>
                </div>
              </div>
              <div>
                <div class="text-center">
                  <div class="socialCompLogoCont">
                    <CompLogos comp="droemer" />
                  </div>
                  <div class="subHeadline uppercase followHeadline">
                    Folge DROEMER KNAUR hier
                  </div>
                  <div class="fCenter">
                    <SocialIcons comp="droemer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </transition>
</template>

<script>
import { useNavigation } from '@/composables/useNavigation'
import btnComp from './btnComp.vue'
import CompLogos from './CompLogos.vue'
import { ref, watch } from 'vue'
import { useAudioState } from '@/composables/useAudioState.js'
import { useVideoPremiereOverlayState } from '@/composables/useVideoPremiereOverlayState.js'

export default {
  components: { btnComp, CompLogos },
  name: 'NavBar',
  props: {
    navigation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      isScrolled: false,
    }
  },
  setup() {
    const { vpmoState } = useVideoPremiereOverlayState()

    const { handleNavigation } = useNavigation()

    // Sound playback ------------------
    const { state, toggleClicked } = useAudioState()

    const audioPlayer = ref(null)
    // State to track if the button has been clicked
    const hasClicked = ref(false)

    const playAudio = () => {
      if (audioPlayer.value) {
        if (!state.isMuted) {
          // Pause the audio if it's currently playing
          audioPlayer.value.pause()
          state.isMuted = true
        } else {
          // Play the audio if it's currently paused
          audioPlayer.value.play()
          state.isMuted = false
        }

        // Remove hint class after first click
        if (!hasClicked.value) {
          hasClicked.value = true
        }

        state.isPlaying = true
      }

      toggleClicked() // Toggle clicked state of sound button
    }

    // Watch for changes in the mute state
    watch(
      () => state.isMuted, // The value you want to watch
      (muteValue) => {
        // The actual value (not destructured as an array)
        if (!muteValue && hasClicked.value) {
          audioPlayer.value.pause()
        } else if (muteValue) {
          audioPlayer.value.play()
        }
      }
    )

    return {
      handleNavigation,
      audioPlayer,
      hasClicked,
      playAudio,
      state,
      vpmoState,
    }
  },
  computed: {
    leftNav() {
      return this.navigation.acf.leftnav || []
    },
    rightNav() {
      return this.navigation.acf.rightnav || []
    },
    combinedNav() {
      return [...this.leftNav, ...this.rightNav]
    },
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive
      if (this.isActive) {
        document.body.classList.add('navOpen')
      } else {
        document.body.classList.remove('navOpen')
      }
    },

    // handleNavigation(link) {
    //   if (link.startsWith('#')) {
    //     // If already on the homepage, scroll to the section
    //     if (this.$route.path === '/') {
    //       this.scrollToHash(link)
    //     } else {
    //       // Navigate to homepage and scroll
    //       this.$router.push('/').then(() => {
    //         this.scrollToHash(link)
    //       })
    //     }
    //   } else {
    //     // If link is not a hash, use router push
    //     this.isActive = false
    //     document.body.classList.remove('navOpen')

    //     // Use a preloader before navigation if necessary
    //     this.showPreloader()
    //     this.$router.push(link).finally(this.hidePreloader)
    //   }
    // },
    scrollToHash(hash) {
      this.isActive = false
      document.body.classList.remove('navOpen')
      this.$nextTick(() => {
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      })
    },
    showPreloader() {
      // Logic to display a preloader
      const preloader = document.getElementById('preloader')
      if (preloader) {
        preloader.style.display = 'block'
      }
    },
    hidePreloader() {
      // Logic to hide the preloader
      const preloader = document.getElementById('preloader')
      if (preloader) {
        preloader.style.display = 'none'
      }
    },
  },
}
</script>

<style scoped>
/* Add transition styles for the fade effect */
.fade-leave-active {
  transition: opacity 0s ease !important;
}

.fade-enter-active {
  transition: opacity 1s ease !important;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Background Sound ------------------ */
#background_sound {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  max-width: 1720px;
  display: flex;
  justify-content: flex-end;
  padding: 0 3rem 3rem;
}

#background_sound button {
  pointer-events: all;
}

#background_sound.hint button {
  transform: scale(1);
  animation: click 2s ease infinite;
}

@keyframes click {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

#background_sound audio {
  display: none;
}

@media (max-width: 576px) {
  #background_sound {
    padding: 0 1rem 1rem;
  }
}

/* Header Styles ------------------ */
.nav-container {
  position: relative;
  z-index: 10002;
}
.deskNav .nav-container > div {
  @apply my-4;
}
.deskNavLogo {
  max-width: 185px;
  height: auto;
}

/* Hamburger Menu */
.fiButtonWrapp {
  position: relative;
  width: 46px;
  height: 46px;
  @apply rounded-full border-2 border-white cursor-pointer;
}
.fiButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
  height: 16px;
  cursor: pointer;
}

.fiButton b {
  position: absolute;
  left: 0;
  width: 22px;
  height: 2px;
  background-color: white;
  transition: all 0.2s ease-in-out;
}

.fiButton b:nth-child(1) {
  top: 0;
}

.fiButton b:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.fiButton b:nth-child(3) {
  top: 100%;
  transform: translateY(-100%);
}

/* Active state styles */
.fiButton.active b:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  background-color: transparent;
}

.fiButton.active b:nth-child(2) {
  transform: rotate(45deg);
}

.fiButton.active b:nth-child(3) {
  top: 52%;
  transform: translateY(-50%) rotate(-45deg);
}
.overlayCont {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}
/* Fullscreen Overlay */
.header_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.header_overlay-nav {
  list-style: none;
  padding: 0;
  padding-top: 140px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.header_overlay-nav li a {
  color: white;
}

/* Add this CSS to prevent scrolling when overlay is active */
body.navOpen {
  overflow: hidden;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
}

@media (min-width: 993px) {
  .header_overlay {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .mobileLogoButton {
    margin-left: -46px;
  }
}

@media (max-width: 576px) {
  .deskNavLogo {
    max-width: 140px;
  }
}
</style>
