<template>
  <Teleport to="#appfi">
    <div v-if="vpmoState.vpmo" class="video_modal__wrapper bg-dark relative">
      <div class="video_modal__inner_wrapper px-5 lg:px-20">
        <!-- Video source dynamically changes based on device type -->
        <video ref="video" playsinline controls :src="videoSource"></video>
        <btn-comp
          v-show="!videoState.videoIsPlaying"
          variant="outline"
          btntype="button"
          name="SKIP VIDEO"
          class="skip-while-playing-btn"
          @click="closeVideoModal()"
        />
      </div>
      <div
        class="video_modal__overlay pt-20 md:pt-15 pb-5 flex flex-col justify-around items-center active index"
        @click="playVideo"
      >
        <img
          class="video_modal__overlay_image"
          :src="block.attrs.data.modal_image.url"
          :alt="block.attrs.data.modal_image.alt"
        />
        <div class="video_modal__overlay_title">
          <strong class="bigHeadline">{{
            block.attrs.data.modal_video_title
          }}</strong>
          <span class="subHeadline bigger">{{
            block.attrs.data.modal_video_subtitle
          }}</span>
        </div>
        <div
          class="video_modal__overlay_ui flex flex-col justify-center items-center"
        >
          <img
            src="https://fitzek-kalender-public.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/09/11155546/fitzek-play-button.svg"
            alt="Play Video"
          />
          <h3 class="mt-5 mb-5 subHeadline bigger text-light">
            {{ block.attrs.data.modal_play_text }}
          </h3>
          <btn-comp
            variant="outline"
            btntype="button"
            name="SKIP VIDEO"
            @click="closeVideoModal()"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
// Define props
import { defineProps, ref, computed } from 'vue'
// On mounted hook to ensure event listeners are added once the component is mounted
import { onMounted, onBeforeUnmount } from 'vue'
// Composables
import { useAudioState } from '@/composables/useAudioState.js'
import { useVideoPlaying } from '@/composables/useVideoPlaying.js'
import { useVideoPremiereOverlayState } from '@/composables/useVideoPremiereOverlayState.js'
// Components
import btnComp from '@/components/framework/btnComp.vue'

const { vpmoState } = useVideoPremiereOverlayState()

// Use defineProps to destructure block
const props = defineProps({
  block: Object, // Define block as a prop with object type
})

vpmoState.vpmoInit = false

// Close the video modal
const closeVideoModal = () => {
  vpmoState.vpmo = false
  vpmoState.vpmoInit = true
}

const video = ref(null) // Reference to the video element
const { state } = useAudioState() // Get audio state
const { videoState } = useVideoPlaying() // Get video state

// Function to check if the device is a touch device (mobile/tablet)
const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

// Computed property to determine which video link to use
const videoSource = computed(() => {
  return isTouchDevice()
    ? props.block.attrs.data.modal_video_link_mobile
    : props.block.attrs.data.modal_video_link
})

const playVideo = () => {
  const overlay = document.querySelector('.video_modal__overlay')
  if (overlay) {
    // Remove 'active' class immediately
    overlay.classList.remove('active')

    // Remove 'index' class after 0.3 seconds
    setTimeout(() => {
      overlay.classList.remove('index')
    }, 300)

    // Start playing the video
    if (video.value) {
      video.value.play()
      onLocalVideoPlay() // Trigger video play state
    }
  }
}

// Function to handle video play
const onLocalVideoPlay = () => {
  videoState.videoIsPlaying = true
  state.isMuted = false
}

// Function to handle video pause
const onLocalVideoPause = () => {
  videoState.videoIsPlaying = false
  state.isMuted = state.isClicked ? true : false
}

// Function to handle video end
const onLocalVideoEnd = () => {
  const overlay = document.querySelector('.video_modal__wrapper')

  videoState.videoIsPlaying = false
  state.isMuted = state.isClicked ? true : false

  setTimeout(function () {
    if (overlay) {
      // Remove 'active' class immediately
      overlay.classList.add('final-fade')
    }
  }, 1000)

  setTimeout(function () {
    vpmoState.vpmo = false
    vpmoState.vpmoInit = true
  }, 2000)
}

// Event listeners for video state changes
const addVideoEventListeners = () => {
  if (video.value) {
    video.value.addEventListener('play', onLocalVideoPlay)
    video.value.addEventListener('pause', onLocalVideoPause)
    video.value.addEventListener('ended', onLocalVideoEnd)
  }
}

onMounted(() => {
  addVideoEventListeners()
})

onBeforeUnmount(() => {
  if (video.value) {
    video.value.removeEventListener('play', onLocalVideoPlay)
    video.value.removeEventListener('pause', onLocalVideoPause)
    video.value.removeEventListener('ended', onLocalVideoEnd)
  }
})
</script>

<style>
.section[data-section-id='acf/videomodal'] {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 0;
}

.subHeadline.bigger {
  font-size: 2.5rem;
}

@media (max-width: 1024px) {
  .subHeadline.bigger {
    font-size: 1.65rem;
  }
}

@media (max-width: 639px) {
  .subHeadline.bigger {
    font-size: 1.25rem;
  }
}
</style>

<style scoped>
.video_modal__wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.video_modal__wrapper.final-fade {
  transition: 1s all ease;
  opacity: 0;
}

.video_modal__overlay {
  inset: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  isolation: isolate;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s all ease;
  z-index: -3;
}

.video_modal__overlay.active {
  opacity: 1;
}

.video_modal__overlay.active.index {
  z-index: 3;
}

.video_modal__overlay::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--cDark);
  width: 100%;
  height: 100%;
  z-index: -2;
}

.video_modal__overlay_title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: almaq-rough, serif;
}

.video_modal__overlay_title strong {
  color: var(--cRed);
}

.video_modal__overlay_image {
  max-width: 500px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.bigHeadline {
  margin-bottom: 0;
}

.video_modal__overlay_ui img {
  max-width: 4rem;
  height: auto;
}

.video_modal__inner_wrapper video {
  border-radius: 5px;
}

.skip-while-playing-btn {
  position: absolute;
  bottom: 13rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .video_modal__overlay_image {
    max-width: 375px;
  }
}
</style>
