import { reactive } from 'vue'

// Singleton state
const vpmoState = reactive({
  vpmo: true,
  vpmoInit: true,
})

export const useVideoPremiereOverlayState = () => {
  return {
    vpmoState,
  }
}
